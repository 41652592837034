import * as React from "react"
// import { Link } from "gatsby"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"

const Footer = props => (
  <footer className=" bg-black p-16">
    <div className="grid md:grid-cols-3 grid-cols-1 gap-10 mb-7">
      <div className=" md:w-6/12">
        <img src={props.image} alt="parkside logo" />
        <span className=" text-white text-xs">
          Taking your projects from concept to completion.
        </span>

        <div className="flex mt-7 items-center">
          <FaFacebookF className="text-white mr-4" />

          <FaTwitter className="text-white mr-4" />

          <FaInstagram className="text-white" />
        </div>
      </div>

      <div className="flex flex-col text-sm">
        <h4 className="text-white">Get in touch</h4>
        <ul className="text-white m-0">
          <li>
            1200 century way <br />
            Thorpe park <br />
            Leeds <br />
            West Yorkshire <br />
            LS15 8ZA
          </li>
          <li>
            <a
              href="mailto:Liam@parksidebuildingcontractors.co.uk
"
            >
              E:Liam@parksidebuildingcontractors.co.uk
            </a>
          </li>
          <li>
            {" "}
            <a href="tel:+44 7494 649556"> M: +44 7494 649556</a>
          </li>
        </ul>
      </div>

      <div className=" text-sm">
        <h4 className="text-white m-0 mb-4">What we do</h4>
        <ul className="text-white m-0">
          <li>
            {" "}
            <a href="https://www.https://parksidebuildingcontractors.co.uk/services/">
              Services
            </a>
          </li>
          <li>
            {" "}
            <a href="https://www.https://parksidebuildingcontractors.co.uk/">
              Our Work
            </a>
          </li>
          <li>
            {" "}
            <a href="https://www.https://parksidebuildingcontractors.co.uk/about/">
              Company
            </a>
          </li>
        </ul>
        <a className="text-green-400" href="#contact">
          GET A FREE QUOTE
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
